import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {

  img: string; // Agrega esta línea si se usa la propiedad 'img' en la plantilla
  chkList: any[]; // Agrega esta línea si se usa la propiedad 'chkList' en la plantilla
  desc: string;
  
  @Input() List: any[];
  @Input() titleSectionProp: any[];


  constructor() { }

  ngOnInit() {
  }

}
