import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

interface DataBanner {
  title      : string;
  imgBanner  : string;
  background : string;
  iconTitle  : string;
}

@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.css'],
})
export class TopBannerComponent implements OnInit {

  private urlImgBanner = `assets/images/banner/`;

  @Input()  data : DataBanner;
  @ViewChild('bgImg', { static: true }) containImg: ElementRef<HTMLElement>;

  constructor() { }
  
  ngOnInit() {
    const { background } = this.data;
    this.setStyleBackground( background );
  }

  setStyleBackground( background: string ){
    if (background){
      this.containImg.nativeElement.style
      .backgroundImage = `url(${this.urlImgBanner}${background})`;
    }else{
      this.containImg.nativeElement.style
      .backgroundImage = `url(${this.urlImgBanner}default-banner-bg.png)`;
    }
  }

  getUrlImageBanner(){
    return this.urlImgBanner + this.data.imgBanner;
  }

}
