import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-choose-us',
  templateUrl: './choose-us.component.html',
})
export class ChooseUsComponent implements OnInit {

  
  img: string; // Agrega esta línea si se usa la propiedad 'img' en la plantilla
  chkList: any[]; // Agrega esta línea si se usa la propiedad 'chkList' en la plantilla
  desc: string; // Agrega esta línea si se usa la propiedad 'desc' en la plantilla
  // Agrega otras propiedades según sea necesario
  
  @Input() lists: any[];
  constructor() { }

  ngOnInit() {
  }

}
