import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-partner',
  templateUrl: './our-partner.component.html',
})
export class OurPartnerComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center',
    // title: 'OUR PARTNERS',
    subTitle: 'Nuestros clientes obtienen resultados',
  };

  List : any[] = [
    { img:  'fidalga.png' },
    { img:  'tahuichi.png', },
    { img:  'sombreros burcal.png', },
    { img:  'tigo.png', },
    { img:  'ministerio.png', },
    { img:  'saguapac.png', },
    { img:  'linde.png', },
    { img:  'novo.png', },
    { img:  'linea-y-figura.png', },
    { img:  'ayca.png', },
    { img:  'physio-center.jpg', },
  ];

  hover : boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
