import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PluginsService } from '../../xamin/plugins.service';
import { topMenuBarItems } from '../../../constants/menu';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styles: [],
  encapsulation: ViewEncapsulation.None
})
export class IndexComponent implements OnInit {

  headeClass = 'style-two';
  logoImage = './assets/images/logo_white.png';
  footerLogoImage = './assets/images/logo.png';

  contactInfo: any = {
    contactNumber: '+59175511448',
    email: 'contacto@ticsys.com.bo',
    direccion: 'Avenida Roca y Coronado, calle Sarany #2115, Santa Cruz de la Sierra Bolivia',
    contactNumberUsa: '+1 (754) 234-2026',

  };



  constructor(private plugins: PluginsService) { }

  public navItems: any = topMenuBarItems;

  ngOnInit() {
    // Init all plugins...
    const current = this;
    current.plugins.revolutionSlider();
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function() {
      current.plugins.index();
    }, 200);
  }

}
