const topMenuBarItems = [
  {
    href: '',
    title: 'INICIO',
    isCurrent: true,
   
  },
  {
    href: '/about-us',
    title: 'NOSOTROS'
   
  },
  {
    // href: '/services',
    href: '/Desarrollo de Software a Medida',
    title: 'SERVICIOS',
    submenu : [
      {
        href: '/services/data-analytics',
        title: 'Desarrollo de Software a Medida'
      },
      {
        href: '/services/data-visualization-services',
        title: 'Desarrollo de Aplicaciones Móviles'
      },
      {
        href: '/services/data-management',
        title: 'Desarrollo Web'
      },
      {
        href: '/services/big-data-services',
        title: 'Redes y Telecomunicaciones'
      },

      {
        href: '/services/business-intelligence',
        title: 'Business Intelligence'
      },
     
    ]
  },
  {
    href: '/our-cases/portfolio-3-columns',
    title: 'PORTAFOLIO',
  
  },

  {
    href: '/pages/contact-us',
    title: 'CONTACTO',
  
  },
  
];

const servicesMenu =  [
  {
    href: '/services/data-analytics',
    title: 'Desarrollo de Software a Medida'
  },
  {
    href: '/services/data-visualization-services',
    title: 'Desarrollo de Aplicaciones Móviles'
  },
  {
    href: '/services/data-management',
    title: 'Desarrollo Web'
  },
  {
    href: '/services/big-data-services',
    title: 'Redes y Telecomunicaciones'
  },

  {
    href: '/services/business-intelligence',
    title: 'Business Intelligence'
  },


];

export { servicesMenu, topMenuBarItems };
