import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
@Component({
  selector: 'app-revolution-slider2',
  templateUrl: './revolution-slider2.component.html'
})
export class RevolutionSlider2Component implements OnInit {
  constructor(private _router: Router,) { }
  imgSrc = './assets/revslider/assets';
  ngOnInit() {
  }

  contactenos(){
      this._router.navigate(['pages', 'contact-us']);
  }

}
