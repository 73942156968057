import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-vertical-one',
  templateUrl: './section-vertical-one.component.html'
})
export class SectionVerticalOneComponent implements OnInit {

  titleSectionProp: any = {
    title: 'NUESTROS SERVICIOS',
    subTitle: 'Brindamos servicios útiles',
    description: ''
  };

    // tslint:disable-next-line:max-line-length
  Lists: any[] = [
    {
      title: 'Software de Inventario',
      img: 'inventario.jpeg',
      desc: 'En Ticsys, nos enorgullece presentar nuestro excepcional expertise en el desarrollo de software de inventario. Hemos trabajado incansablemente para crear soluciones tecnológicas que transforman la gestión de existencias para empresas de todos los tamaños y secto Nuestros equipos de desarrollo altamente calificados no solo crean software de inventario a medida, sino que también colaboran estrechamente con nuestros clientes para comprender a fondo sus necesidades específicas. Esto nos permite diseñar y desarrollar soluciones de software altamente personalizadas que optimizan la eficiencia operativa, reducen los costos y eliminan errores en la gestión de inventario. ',
      // chkList: ['Experiencia en Desarrollo', 'Soluciones Personalizadas', 'Colaboración Estrecha con Clientes', 'Funcionalidades Avanzadas', 'Compromiso con la exelencia', 'Control de Stock', 'Escalabilidad y Adaptabilidad', 'Getion de compras', 'Gestion de ventas', 'Gestion de roles'],
    },

    {
      title: 'Software de Gestión de Spa',
      img: 'spa.png',
      // desc: `¡Optimizá la gestión de tu spa con nuestro software! En nuestro equipo, contamos con una amplia experiencia en el desarrollo de software especializado para clínicas de belleza. Simplificá la administración de tu negocio de spa sin estrés y permití que tu equipo se enfoque en lo que realmente importa: ofrecer servicios de calidad a tus clientes.\n
      // Con nuestro sistema, tendrás el control total en tus manos. Supervisá de cerca las cuentas, gestioná los inventarios y coordiná eficientemente a tu equipo de trabajo.
      // \nFuncionalidades`,
      desc: `¡Optimizá la gestión de tu spa con nuestro software! En nuestro equipo, contamos con una amplia experiencia en el desarrollo de software especializado para clínicas de belleza. Simplificá la administración de tu negocio de spa sin estrés y permití que tu equipo se enfoque en lo que realmente importa: ofrecer servicios de calidad a tus clientes.\n
      Con nuestro sistema, tendrás el control total en tus manos. Supervisá de cerca las cuentas, gestioná los inventarios y coordiná eficientemente a tu equipo de trabajo.
      `,
      // chkList: ['Gestión de inventarios.', 'Gestión de almacenes.', 'Gestión de compras.', 'Punto de venta (POS)', 'Gestión de promociones','Historial clínico', 'Gestión de reservas en línea', 'Galería de fotos de antes y después', 'Cotizaciones', 'Gestión de clientes', 'Gestión de reservas en línea', 'Calendario de citas.', 'Notificaciones y recordatorios.', 'Nóminas y comisiones', 'Planificación de personal', 'Estadísticas e informes en tiempo real', 'Acceso online desde cualquier dispositivo'],
    },

    
    {

      title: 'Software de Almacen',
      img: 'almacen.jpeg',
      desc: 'Optimiza las operaciones de almacenamiento y distribución con un sistema de gestión de almacenes que permite controlar inventarios, gestionar entradas y salidas de mercancía, y monitorear en tiempo real el estado de los productos. Mejora la eficiencia y precisión de tu logística con herramientas avanzadas de seguimiento y reportes.'
      // desc: 'Seguir las operaciones de almacenamiento y distribución con un Sistema de Almacenes que pueda gestionar:',
      // chkList: ['Gestión de ubicaciones de mercadería.', 'Integración con dispositivos de inventario RFID, códigos de barra.', 'Integración con sistemas de gestión interna', 'Gestión de personal para asignar la mano de obra y supervisar el rendimiento.', 'Etiquetado para gestión de la llegada de nuevo inventario.', 'Capacidad avanzada de elaboración de informes.', 'Diseño adaptado a tu teléfono para que no estes encadenado a un escritorio.', 'Un diseño que permita que los flujos de trabajo sean eficientes']
    },{
      title: 'Networking',
      img: 'networking.jpeg',
      desc: 'Contamos con una selección de arquitecturas de gestión y control de redes para abordar los requisitos de organizaciones de todos los tipos y tamaños, asegurandonos que  tenga modelos compatibles y flexibles para implementar redes preparadas para el futuro de forma sencilla, fiable, segura y a escala, Asegurandonos de aprovisionar, gestionar, optimizar y solucionar, con facilidad, los problemas de una red empresarial de acceso múltiple y alto rendimiento a través de un único panel web o aplicación móvil nativa. Soluciones para NEXTgen Firewalls, switches stackeables y routers.',
      // chkList: ['Juniper', 'Fortinet', 'Sophos', 'Paloalto', 'Powerconnet', 'Grandstream', 'Mikrotik']
    },{
      title: 'Big Data',
      img: 'big-data.jpeg',
      desc: 'Construimos aplicaciones Apache Spark en Java, Python o Scala que analicen múltiples flujos de datos en paralelo, extraemos datos de diferentes sistemas, los transformamos y los entregamos para su posterior análisis. Ventajas de utilizar Apache Spark Mejora el desempeño de aplicaciones dependientes de datos. Simplifica el proceso de desarrollo de soluciones inteligentes. Unifica algoritmos para que trabajen conjuntamente en diversas tareas. Dispone de una API para Java, Phyton y Scala; también APIs para transformar y manipular datos semiestructurados. Facilita la integración con sistemas de archivos como HDFS de Hadoop, Cassandra, HBase, MongoDB y el S3 de AWS.',
      // desc: 'Construimos aplicaciones Apache Spark en Java, Python o Scala que analicen múltiples flujos de datos en paralelo, extraemos datos de diferentes sistemas, los transformamos y los entregamos para su posterior análisis. Ventajas de utilizar Apache Spark Mejora el desempeño de aplicaciones dependientes de datos. Simplifica el proceso de desarrollo de soluciones inteligentes. Unifica algoritmos para que trabajen conjuntamente en diversas tareas. Dispone de una API para Java, Phyton y Scala; también APIs para transformar y manipular datos semiestructurados. Facilita la integración con sistemas de archivos como HDFS de Hadoop, Cassandra, HBase, MongoDB y el S3 de AWS. Ofrece bibliotecas de alto nivel para mejorar la productividad de los desarrolladores. Posee tolerancia a fallos implícita. Combina SQL, streaming y análisis de gran complejidad.',
      // chkList: ['Integración de Datos Completa', 'Transformación Avanzada', 'Automatización Eficiente', 'Análisis Predictivo y Reporting', 'Soporte Personalizado',]
    },{
      title: 'Antenas',
      img: 'antenas.jpeg',
      desc: 'Nuestras antenas de radioenlaces cubren una gama de frecuencias, son la solución ideal para garantizar la transmisión de forma continua y sin perdida, adaptandonos a condiciones extremas realizamos el diseño completo en cuanto a elección del sitio de instalación, relevamiento del perfil del terreno y cálculo de la altura del mástil para la antena, cálculo completo del radio enlace, estudio de la trayectoria del mismo y los efectos a los que se encuentra expuesto. Prueba posterior a la instalación del radio enlace, y su posterior puesta en servicio con tráfico real.',
      // chkList: ['Cambium', 'Alpha', 'Aviat', 'Azotel', 'Motorola', 'Nexus', 'Cel-Fi', 'Nextivity', 'Mimosa']
    },{
      title: 'Cámaras de Red',
      img: 'camaras.jpeg',
      desc: `Brindamos soluciones de red en sistemas de videovigilancia con aplicaciones de análisis inteligentes que se respaldan con una formación de alta calidad. Clientes de todo el mundo utilizan estas tecnologias para proteger a personas,propiedad y mejorar el rendimiento empresarial, utilizamos tecnologias para todo tipo de ambientes ya sea en exteriores como en interiores adptandonos a los requeriemitos del cliente. `,
      // chkList: ['Axis', 'Hikvision', 'Dahua', 'Vivotek', 'Avigilon', 'AV Costar', 'Zmodo', 'Amcrest',]
    },
    {
      title: 'Wifi',
      img: 'wifi.jpeg',
      desc: `Le ofrecemos sistemas wifi que se adaptan a casi cualquier presupuesto, requisito de rendimiento o escenario de implementación. Nuestros APs proporcionan un acceso seguro y fiable sin importar lo difícil que sea el entorno, basando siempre en aritas como ser:
      *Alta densidad de clientes
      *Materiales de construcción poco favorables para Wi-Fi
      *Entornos exteriores desafiantes.
      diseñaremos su red inalambrica con tecnologías patentadas que superan las características habituales para garantizar conexiones superiores, escalablas para brindar experiencia de usuario increíbles.
      `,
      // chkList: ['Ruckus', 'Unifi', 'Aruba', 'Ubiquity', 'Microtik', 'Linksys', 'Mist']
    },
    {
      title: 'Cableado',
      img: 'cableado.jpeg',
      // desc: 'Nuestras soluciones en infraestructura son el completo ideal para su red. Toda la conectividad que necesita desde el servidor o hasta el usuario final, ofreciendole soluciones de UTP o fibra de acuerdo los requerimientos, le facilitamos el diseño, relevamiento he implementacion, las empresas están experimentando transformaciones drásticas por las cuales deben adoptar nuevas estrategias para una Ethernet industrial. En vista de la transición a controladores, computadoras, control de movimiento de alta velocidad, cámaras y componentes electrónicos de potencia conectados a Internet IOT, se requieren enfoques novedosos porque, posiblemente, las prácticas previas en materia de redes ya no sirvan, por lo que estamos para examinar la topología del cableado para determinar si se debe usar un cableado estructurado o un cableado de punto a punto, tomando consideraciones como: especificaciones de diseño, la longevidad de la red, distancias fisicas, capacidad de mantenimiento y la instalación. Estas consideraciones ayudarán a elegir la mejor opción de cableado para su empresa.',
      desc: 'Nuestras soluciones de infraestructura ofrecen conectividad completa desde el servidor hasta el usuario final, con opciones de UTP o fibra. Nos encargamos del diseño, relevamiento e implementación. Evaluamos la topología del cableado para determinar la mejor opción entre cableado estructurado o de punto a punto, considerando diseño, longevidad de la red, distancias físicas, capacidad de mantenimiento e instalación.',
      // chkList: ['Furukawa', 'Panduit']
    },
  ];

  constructor() { }
  ngOnInit() { }

}