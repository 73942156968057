import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

interface Card {
  title: string;
  desc: string;
  img: string;
}

@Component({
  selector: 'app-about-card',
  templateUrl: './about-card.component.html',
  styleUrls: [ './about-card.component.scss' ]
})
export class AboutCardComponent implements OnInit, AfterViewInit {

  @Input() cardData: Card[];

  constructor() {

  }
  
  ngOnInit() { }

  ngAfterViewInit(): void {
    for (let i = 0; i < this.cardData.length; i++) {
      const div = document.getElementById(`${i}`);
      div.style.backgroundImage = `url(${this.cardData[i].img})`;
    }
  }


}
