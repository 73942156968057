import {Component,    Input, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-portfolio-box',
  templateUrl: './portfolio-box.component.html',
  styleUrls : ['./portfolio-box.component.css']
})
export class PortfolioBoxComponent implements OnInit {

  @Input() portfolioList:any[];
  // textHidden : boolean = false;
  // indexButton: number;
  // buttonText: string = 'ver mas';

  textHidden = false;
  indexButton: number | null = null;
  buttonText: string = 'Ver más';


  constructor() { }

  ngOnInit() {
  }

 toggleText(index: number): void {
    if (this.indexButton === index) {
      this.indexButton = null;
      this.textHidden = false;
      this.buttonText = 'Ver más';
    } else {
      this.indexButton = index;
      this.textHidden = true;
      this.buttonText = 'Ver menos';
    }
  }

}

